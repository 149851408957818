import React ,{Fragment,useEffect,useContext}from 'react';
import './Servicepage.scss';
import {Applicationcontext} from "../../Applicationcontext";
import {Grid,Divider} from '@mui/material';
import {DoubleArrow} from '@mui/icons-material';
import {FeaturesDisplay} from "../FeaturesDisplay/FeaturesDisplay";







  
const  Servicepage=({display_full = true})=> {


const Applicationstate = useContext(Applicationcontext)

const {ResponsiveMediaQuery,ServerProcessLoading,UserProfile,Functions,Appstate} = Applicationstate

const {CompanyProfile} = UserProfile


const {company_logo,company_motto,company_name,theme_maincolor,theme_subcolor,theme_decoration_color}=CompanyProfile 



useEffect(()=>{//----------COMPONENT DID MOUNT
//$(window).scrollTop(25)//scroll to top of the component
   

    

    
    


return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
   // eslint-disable-next-line react-hooks/exhaustive-deps 
},[])




const {mobile_xs,tablet_sm} = ResponsiveMediaQuery






 
    
   
return ( //------------------- RETURN HTML
<Fragment>
<div id='Servicepage'>
<div className="content-padding py-0 wow fadeInUp" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
  <div className="py-2">
    
 <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
  <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
  <h1 className="mb-0">Custom IT Solutions for Your Successful Business</h1>
</div>

     <br />
    <Grid container spacing={2}> 

     <Grid item xs={12} sm={6} md={4}>
        <div className='service-card-frame'> 
            <div className='service-image'>
              <img src='images/Debt-Collection.jpg' alt='' style={{width:'100%',maxHeight:'100%'}}/>
              <h4>Debt Collection</h4>
               <span>
                We offer debt collection services to various financial institutions as follows 
               </span>
               <ul>
                <li>Banks</li>
                <li>Financial cooperative societies</li>
                <li>Cooperative groups </li>
                <li>Various individuals</li>
                <li>Other Financial institutions</li>
               </ul>
            </div>
        
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <div className='service-card-frame'> 
            <div className='service-image'>
              <img src='images/Court-Brokers-Service.jpg' alt='' style={{width:'100%',maxHeight:'100%'}}/>
              <h4>Court Brokers Service</h4>
               <span>
               At RockcityTakers LTD, we specialize in providing comprehensive support throughout
                every stage of the court process. Our team of seasoned professionals is dedicated
                 to simplifying the legal journey for individuals, businesses, and legal professionals alike.
                  Whether you're facing a civil case, criminal charges, or any legal matter that requires court
                   intervention, our Court Broker services are designed to streamline the entire experience.
               </span>
             
            </div>
        
        </div>
      </Grid>

      
      <Grid item xs={12} sm={6} md={4}>
        <div className='service-card-frame'> 
            <div className='service-image'>
              <img src='images/Real-Estate-Agency.jpg' alt='' style={{width:'100%',maxHeight:'100%'}}/>
              <h4>Real-Estate Agency</h4>
               <span>
               where we redefine real estate services to exceed your expectations. Our comprehensive suite 
               of services is crafted to cater to every facet of your real estate needs, ensuring a seamless
                and rewarding experience.
               </span>
               <ul>
                <li>Buying and selling of estates</li>
                <li>Leasing and renting of houses</li>
                <li>Renovating of related properties</li>
                <li>Attracting investors</li>
               </ul>
             
            </div>
        
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <div className='service-card-frame'> 
            <div className='service-image'>
              <img src='images/Tendering-and-Procurement.jpg' alt='' style={{width:'100%',maxHeight:'100%'}}/>
                <h4>Tendering and Procurement</h4>
               <span>
               We hire, provide, manage and sub-contract professionals for technically related 
               services and involve ourselves in the district, provincial and national services 
               in the procuring and tendering processes.
               </span>
             
            </div>
        
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <div className='service-card-frame'> 
            <div className='service-image'>
              <img src='images/Public-Auctioneers.jpg' alt='' style={{width:'100%',maxHeight:'100%'}}/>
              <h4>Public Auctioneers</h4>
               <span>
               By the power vested upon us, we offer auction services to our clients where 
               necessary under the rule of law of the United Republic of Tanzania.
               </span>
             
            </div>
        
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <div className='service-card-frame'> 
            <div className='service-image'>
              <img src='images/Stationery&General-Supply.jpg' alt='' style={{width:'100%',maxHeight:'100%'}}/>
              <h4>Stationery & General Supply</h4>
               <span>
                We offers Stationery & General Supply, we recognize that every individual and business has unique needs.
                That's why we offer personalized solutions to cater to your specific requirements.
                 Whether you need customized stationery sets for your team or specialized supplies
                  for a particular project, we are here to make it happen.
               </span>
             
            </div>
        
        </div>
      </Grid>


      <Grid item xs={12}>
        <div className='segment-frame'>
        <Grid container spacing={0}> 

          <Grid item xs={12} md={6}>
             <div className='marketing-segment-image'>
                
                 <div className='marketing-photo' style={{backgroundImage:`url(images/Marketing-Segments.jpg)`}}>
                            
         
                </div>
         

             </div>
             </Grid>

            <Grid item xs={12} md={6}>
               <div className='marketing-segment-textframe'>
                 <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
                     <h1 className="mb-0">Our Marketing Segments & Targeted Audience/Clients </h1>
                  </div>
                     <span>
                      We consider Banks and Sacco institutions as major customers with bulk of business
                      expected from them. Long-term contracts are however more possible from the Government.
                      
                      <br />
                       <b>So Far we  have identified and worked with various legal institutions and delivery
                       our comprehensive services and noted below </b>
                     </span>

                      <ul>
                        <li>Municipalities in Mwanza</li>
                        <li>Banking Institutions</li>
                        <li>Sacco Institutions</li>
                        <li>Courts</li>
                      </ul>


               </div>
              </Grid>   

        </Grid>
        </div>
      </Grid>







 </Grid>

  </div>
</div>


</div>


</Fragment>
  );
}
export {Servicepage};
    
    