import React ,{Fragment,useEffect,useContext,useState}from 'react';
import './Homepage.scss';
import {Applicationcontext} from "../../Applicationcontext";
import {Aboutpage} from "../Aboutpage/Aboutpage";
import {Grid} from "@mui/material";
import {Badge} from "@mui/icons-material"








  
const  Homepage=({display_full = true})=> {


const Applicationstate = useContext(Applicationcontext)

const {ResponsiveMediaQuery,ServerProcessLoading,UserProfile,Functions,Appstate} = Applicationstate

const {CompanyProfile} = UserProfile


const {company_logo,company_motto,company_name,theme_maincolor,theme_subcolor,theme_decoration_color}=CompanyProfile 



useEffect(()=>{//----------COMPONENT DID MOUNT
//$(window).scrollTop(25)//scroll to top of the component
   








 
    

    
    


return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
   // eslint-disable-next-line react-hooks/exhaustive-deps 
},[])



const [OUR_STAFF_LIST,SET_OUR_STAFF_LIST] = useState([
  {
  name:'KASSANGA H KASSANGA',
  profile:'male-avatar.svg',
  position:'C.E.O',
  education:'(BA Marketing AIMST University Malaysia)',
  description:`
  Accomplished management professional with 5 years career progression 
  in auctioneering operations. Proven effective leader who is skilled 
  in development and oversight of high- performing muti-disciplined and
  quality-oriented teams. Adept problem solver, employing creativity and
  innovation to overcome challenging and complex issues across employees,
  processes and costs. Focused and meticulous in all financial and regulatory
  compliance objectives to strategically plan and execute budgets. Well versed
  in employee, vendor and partner relations.`
  },
  {
  name:'MAULID IBRAHIM MSOLOPA',
  profile:'images/staff_profile/MSOLOPA.png',
  position:'Operations Manager',
  education:'(Dip. Flight Operations & Dispatch, Nairobi Aviation College)',
  description:`
   Accomplished management professional with more than 5 years career progression in the 
   operations and logistics industry. Msolopa is in charge of developing and implementing
   new and operational policies and procedures. He also makes important policies, plans 
   and strategic decisions. Together with his team, he ensures all legal documents are 
   filed and they monitor compliance with laws and regulations.`
  },
  {
  name:'REBECCA NGURU',
  profile:'images/staff_profile/REBECCA.jpeg',
  position:'Administrator',
  education:'(BA Communication, Daystar University)',
  description:`
   Rebecca supervises and manages administrative staff and reports to the C.E.O.
    She is in charge of developing maintaining information systems to support 
    effective working. She also provides administrative support and office systems
     within the organization to ensure customer satisfaction.`
  },

 ])


 const [OUR_PARTNERS_LIST,SET_OUR_PARTNERS_LIST] = useState([
  {title:'JUDICIARY',logo:'partners/JUDICIARY.png'},
  {title:'NMB',logo:'partners/NMB.webp'},
  {title:'NIC',logo:'partners/NIC.jpg'},
  {title:'NBCL',logo:'partners/NBCL.jpg'},
  {title:'BLACC FINANCE',logo:'partners/BLACC-FINANCE.png'},
  {title:'FINCA MICROFINANCE',logo:'partners/FINCA-MICROFINANCE.png'},
 ])






const OUR_STAFF_LIST_MAP=OUR_STAFF_LIST.map(function(object,index){
  return(
    <Grid item xs={12} sm={6} md={3} lg={4} key={index}> 
       <div className="staff-profile-frame">
         <div className='staff-profile-circle' style={{backgroundImage:`url(${object.profile})`}}>
          
          </div>
         <div className='staff-title-frame'>
            {object.name}
         </div>
         <div className='staff-position'>
          <Badge style={{fontSize:25}}/> {object.position}
         </div>
          <div className='education-frame'><h6>{object.education}</h6></div>
         <div className='staff-description-frame'>
           <span>{object.description}</span> 
         </div>
      
    </div>
  </Grid>
  )
})


const OUR_PARTNERS_LIST_MAP=OUR_PARTNERS_LIST.map(function(object,index){
  return(
    <Grid item xs={6} sm={6} md={2} key={index}> 
       <div className="partner-frame">
               
                <img src={object.logo} alt='' style={{maxWidth:'95%',maxHeight:'95%'}} />

      </div>
  </Grid>
  )
})
  
  


    
   
return ( //------------------- RETURN HTML
<Fragment>
<div id='Homepage'>


<Aboutpage />

<div className='content-padding'>
<Grid container spacing={2}> 


<Grid item xs={12}>
<div className="py-2 wow fadeInUp" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
  <div className="py-0">
    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
      <h5 className="fw-bold text-primary text-uppercase">Why Choose Us</h5>
      <h1 className="mb-0">Our team of seasoned legal professionals brings a wealth of experience to the table</h1>
    </div>
    <div className="row g-3s">
      <div className="col-lg-4">
        <div className="row g-5">
          <div className="col-12 wow zoomIn" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'zoomIn'}}>
            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: 80, height: 80}}>
              <img src='svg/Expert-Guidance.svg' style={{maxWidth:60,color:'#ffff'}} alt='' className="fa fa-cubes text-white"/>
            </div>
            <h4>Expert Guidance</h4>
            <p className="mb-0">
              Our team of seasoned legal professionals brings a wealth of experience to the table.
              We understand the nuances of the legal landscape and provide expert guidance tailored
              to your specific needs.
            </p>
          </div>
          <div className="col-12 wow zoomIn" data-wow-delay="0.6s" style={{visibility: 'visible', animationDelay: '0.6s', animationName: 'zoomIn'}}>
            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: 80, height: 80}}>
               <img src='svg/Strategic-Case-Management.svg' style={{maxWidth:60,color:'#ffff'}} alt='' className="fa fa-cubes text-white"/>
            </div>
            <h4>Strategic Case Management</h4>
            <p className="mb-0">
              We take the helm in managing your case, ensuring all deadlines are met, court appearances 
              are scheduled, and submissions are organized. Our strategic approach is designed to keep 
              your legal journey on track.
              </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4  wow zoomIn" data-wow-delay="0.9s" style={{minHeight: 350, visibility: 'visible', animationDelay: '0.9s', animationName: 'zoomIn'}}>
        <div className="position-relative h-100">
          <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src="images/Why-Choose-Us.png" style={{objectFit: 'cover', visibility: 'visible', animationDelay: '0.1s', animationName: 'zoomIn'}} />
        </div>
      </div>
      <div className="col-lg-4">
        <div className="row g-5">
          <div className="col-12 wow zoomIn" data-wow-delay="0.4s" style={{visibility: 'visible', animationDelay: '0.4s', animationName: 'zoomIn'}}>
            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: 80, height: 80}}>
               <img src='svg/Issue-Resolution.svg' style={{maxWidth:60,color:'#ffff'}} alt='' className="fa fa-cubes text-white"/>
            </div>
            <h4>Issue Resolution</h4>
            <p className="mb-0">
            Challenges are inevitable, but we meet them head-on. Our proactive approach 
            to problem-solving ensures that issues are identified and addressed swiftly,
             preventing unnecessary delays in your legal proceedings.
             </p>
          </div>
          <div className="col-12 wow zoomIn" data-wow-delay="0.8s" style={{visibility: 'visible', animationDelay: '0.8s', animationName: 'zoomIn'}}>
            <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: 80, height: 80}}>
               <img src='svg/Document-Preparation-and-Filing.svg' style={{maxWidth:60,color:'#ffff'}} alt='' className="fa fa-cubes text-white"/>
            </div>
            <h4>Document Preparation and Filing</h4>
            <p className="mb-0">
              We take care of all the paperwork, from drafting legal documents to filing them with the court.
               Our attention to detail minimizes the risk of errors and ensures compliance with court 
               requirements.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</Grid>

<Grid item xs={12}>
        <div className='segment-frame'>
        <Grid container spacing={0}> 

          <Grid item xs={12} md={6}>
             <div className='marketing-segment-image'>
                
                 <div className='marketing-photo' style={{backgroundImage:`url(images/Marketing-Segments-1.jpg)`}}>
                            
         
                </div>
         

             </div>
             </Grid>

            <Grid item xs={12} md={6}>
               <div className='marketing-segment-textframe'>
                 <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
                     <h1 className="mb-0">Our Marketing Segments & Targeted Audience/Clients </h1>
                  </div>
                     <span>
                      We consider Banks and Sacco institutions as major customers with bulk of business
                      expected from them. Long-term contracts are however more possible from the Government.
                      
                      <br />
                       <b>So Far we  have identified and worked with various legal institutions and delivery
                       our comprehensive services and noted below </b>
                     </span>

                      <ul>
                        <li>Municipalities in Mwanza</li>
                        <li>Banking Institutions</li>
                        <li>Sacco Institutions</li>
                        <li>Courts</li>
                      </ul>


               </div>
              </Grid>   

        </Grid>
        </div>
</Grid>


<Grid item xs={12}>
        <Grid container spacing={0}> 

        <Grid item xs={12} md={7}>
               <div className='marketing-segment-textframe-2'>
                 <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
                     <h1 className="mb-0">Our working pillar towards better customer experience  </h1>
                  </div>
                     <b style={{color:theme_maincolor,fontSize:'1.2rem'}}>Prioritizing Customer Needs</b>
                     <br />
                     <span>
                      The basic need of our target customers is excellent and reliable service at all times.
                      Highly trained and experienced staff will be of value to our business in order to meet
                      this need.
                      <br />
                     </span>
                     <b style={{color:theme_maincolor,fontSize:'1.2rem'}}>Competitors</b>
                     <p>
                     We identify that we are in a global a vast number of
                     business with competitors but our prime strategy will be to focus on
                      quality services and affordable services without compromising quality.

                     </p>
                     <b style={{color:theme_maincolor,fontSize:'1.2rem'}}>Key Personnel</b>
                     <p>
                     The management team (executive and non-executive members thereof) though all
                      from the PDI background composes of well distinguished and qualified Personnel
                       who founded the Business concept after working and acquiring vast experiences
                        in every avenue of their careers.

                     </p>


                     
               </div>
              </Grid>   

          <Grid item xs={12} md={5}>
             <div className='marketing-segment-image-2'>
                  <div className='marketing-photo-2'><img src='images/working-pillar.png' alt='' style={{maxWidth:'100%',maxHeight:'100%'}}/> </div>
             </div>
             </Grid>

        </Grid>
   
</Grid>


<Grid item xs={12}>
  <Grid container spacing={0}> 

      <Grid item xs={12}> 
      <div className="section-title text-center position-relative pb-3 mb-5 mx-auto">
      <h1 className="mb-0">Our Organizational Structure</h1>
    </div>
      </Grid>
      <Grid item xs={12}>
        <div className='organizational-frame'>

          <img src='images/organizational-structure.PNG' alt='' style={{maxWidth:'100%'}}/>


        </div>
      </Grid>   


  </Grid>
   
</Grid>


<Grid item xs={12}>
  <Grid container spacing={2}> 

      {OUR_STAFF_LIST_MAP}

  </Grid>
   
</Grid>

<Grid item xs={12}>
  <Grid container spacing={2}> 

         
  <Grid item xs={12}> 
  <div className="section-title text-center position-relative pb-3 mb-2 mx-auto" style={{maxWidth: 600}}>
      <h1 className="mb-0">Our Partners</h1>
    </div>
   </Grid>
     
      {OUR_PARTNERS_LIST_MAP}

  </Grid>
   
</Grid>








</Grid>
</div>




</div>
</Fragment>
  );
}
export {Homepage};
    
    