import React ,{Fragment,useEffect,useContext}from 'react';
import './Contactpage.scss';
import {Applicationcontext} from "../../Applicationcontext";
import {LocationOn,WhatsApp,Instagram,PhoneCallback,Facebook,Email} from '@mui/icons-material';










  
const  Contactpage=({display_full = true})=> {


const Applicationstate = useContext(Applicationcontext)

const {ResponsiveMediaQuery,ServerProcessLoading,UserProfile,Functions,Appstate} = Applicationstate

const {CompanyProfile} = UserProfile


const {phone_number1,phone_number2,company_email1,company_email2,theme_maincolor,theme_subcolor,office_location}=CompanyProfile 



useEffect(()=>{//----------COMPONENT DID MOUNT
// $(window).scrollTop(25)//scroll to top of the component
   



return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
   // eslint-disable-next-line react-hooks/exhaustive-deps 
},[])






 
//style={{backgroundImage:`url(images/about-photo.jpg)`}}
   
return ( //------------------- RETURN HTML
<Fragment>
<div id ='Contactpage'>
<div className="py-2 wow fadeInUp" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
  <div className="container py-2">
    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: 600}}>
      <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
      <h1 className="mb-0">Contact & Company Information</h1>
    </div>
    <div className="row g-5 mb-5">
      <div className="col-lg-4">
        <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeIn'}}>
          <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: 60, height: 60}}>
            <i className="fa fa-phone-alt text-white" />
          </div>
          <div className="ps-4">
            <h5 className="mb-2">Call to ask any question</h5>
            <h4 className="text-primary mb-0"><a href={`tel:${phone_number1}`}>{phone_number1}</a></h4>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s" style={{visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeIn'}}>
          <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: 60, height: 60}}>
            <i className="fa fa-envelope-open text-white" />
          </div>
          <div className="ps-4">
            <h5 className="mb-2">Email Us For Legal Assistance</h5>
            <h4 className="text-primary mb-0">{company_email1}</h4>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s" style={{visibility: 'visible', animationDelay: '0.8s', animationName: 'fadeIn'}}>
          <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: 60, height: 60}}>
            <i className="fa fa-map-marker-alt text-white" />
          </div>
          <div className="ps-4">
            <h5 className="mb-2">Physical Address</h5>
            <h4 className="text-primary mb-0">
            {office_location.physical_address},
            {office_location.opposite_closeto},
            {office_location.region},
            
            </h4>
          </div>
        </div>
      </div>
    </div>
    
    
    <div className="row g-5">
      <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'slideInUp'}}>
      <div className='contact-information-frame'>
        
        <ul>
          <li><b>Company Name : </b>ROCK CITY TAKERS LIMITED </li>
          <li><b>Reg .Number : </b>139118960</li>
          <li><b>Postal Address:</b> {office_location.postal_address}</li>
        </ul>

      </div>
      </div>
      <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s" style={{visibility: 'visible', animationDelay: '0.6s', animationName: 'slideInUp'}}>
       
        <iframe style={{width: '100%', height: '100%', objectFit: 'cover'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1969.6543143279255!2d32.90163572359816!3d-2.500361858180584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19ce7b2a701a9a3d%3A0x79c49587fade20c2!2sRock%20City%20Mall!5e1!3m2!1sen!2stz!4v1676539221270!5m2!1sen!2stz" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
  
      
      </div>
    </div>
  </div>
</div>

</div>
</Fragment>
  );
}
export {Contactpage};
    
    