import React ,{Fragment,useEffect,useState}from 'react';
import Cookies from 'js-cookie';
import {Route,Routes,Navigate} from 'react-router-dom';
import {Aboutpage} from "./components/Aboutpage/Aboutpage";
import {Homepage} from "./components/Homepage/Homepage";
import {Servicepage} from "./components/Servicepage/Servicepage";
import {Portifoliopage} from "./components/Portifoliopage/Portifoliopage";
import {Digitalventure} from "./components/Digitalventure/Digitalventure";
import {Contactpage} from "./components/Contactpage/Contactpage";




const  RouteNavigation = (props) => {



// //eslint-disable-next-line
// const [Authenticated,setAuthenticated] = useState(()=>{
//    var token = Cookies.get("super_user_csrftoken");
//    var active_account = localStorage.getItem('active_account');
//     if(token !==undefined && active_account !==undefined){

   

//       return true
//      }else{

 
//      return false
//     } 
// })







useEffect(()=>{//COMPONENT DID MOUNT






 
    


},[])




  













return (  //=----RETURN MAIN HTML NAVIGATION
<Fragment>
<Routes>

<Route path="/" element={<Homepage  /> } />
   
<Route path="/services" element={<Servicepage  /> } />

<Route path="/digitalventure" element={<Digitalventure  /> } />

<Route path="/portfolios" element={<Portifoliopage  /> } />

<Route path="/about" element={<Aboutpage  /> } />

<Route path="/contact" element={<Contactpage  /> } />

<Route path="*" element={<h1>page not found !</h1> } />

</Routes>
</Fragment>
);}
export {RouteNavigation};

