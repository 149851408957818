import React ,{useEffect,useState,Fragment} from 'react';
import "./App.scss"

import {RouteNavigation} from "./RouteNavigation";
import {ThemeProvider,createTheme} from '@mui/material/styles';
import {Applicationcontext} from "./Applicationcontext";
import Grid from '@mui/material/Grid';

import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Typography } from '@mui/material';
import {useLocation,NavLink,Link} from 'react-router-dom';
import {Done} from '@mui/icons-material';












function App(props){ //-----------MAIN APP
 
const ResponsiveDimention = useTheme();
const mobile_xs = useMediaQuery(ResponsiveDimention.breakpoints.only('xs'));
const tablet_sm = useMediaQuery(ResponsiveDimention.breakpoints.only('sm'));
const desktop_md = useMediaQuery(ResponsiveDimention.breakpoints.only('md'));
const desktop_lg = useMediaQuery(ResponsiveDimention.breakpoints.only('lg'));
const desktop_xl = useMediaQuery(ResponsiveDimention.breakpoints.only('xl'));
  
const Location = useLocation()

const {pathname} = Location

  
  

const [Appstate,setAppstate] = useState(
       {
       server_ip:(function(){
          if(process.env.NODE_ENV==='production'){
           return "/";
          }else{ 
            return  "http://127.0.0.1:8000/";
           }
      
       })(),

      }
)

const [UserProfile,setUserProfile] = useState({
  CompanyProfile:{
     company_logo:'logo.png',
     company_motto:'RockcityTakers',
     company_name:'RockcityTakers',
     theme_maincolor: "#0677f0",
     theme_subcolor: "#EB0989",
     theme_decoration_color: "#FF1493",
     phone_number1:'+255766342969',
     phone_number2:'+255713342969',
     whatsap_number:'+255766342969',
     facebook_handle:'wiflexi_tz',
     instagram_handle:'wiflexi_tz',
     company_email1:'info@rockcitytakers.co.tz',
     company_email2:'support@rockcitytakers.co.tz',
     instagram_link:'https://',
     facebook_link:'https://',
     office_location:{
      postal_address:`P.0.BOX 40994`,
      opposite_closeto:`Makongoro Road`,
      physical_address:`Rock City Mall ,2nd Floor Wing B`,
      region:'Mwanza',
      country:'Tanzania'
     }
    },
   PersonalProfile:{
    username_email:null,
    first_name:null,
    last_name:null,
    middle_name:null,
    profile_photo:null,
    primary_email:null,
    user_type:null
   },
   Permission:{
    role:null,//admin || branch_nanager
    execute: null,
    write: null,
    read:null,
   },

})

const theme = createTheme({
  palette:{
    primary:{
      main:`${UserProfile.CompanyProfile.theme_maincolor}`
    },
    secondary:{
      main:`${UserProfile.CompanyProfile.theme_subcolor}`
    },
    info:{
      main:`${UserProfile.CompanyProfile.theme_decoration_color}`
    },
    mode: `${Appstate.dark_theme===true?'dark':'light'}`,
    // mode: 'dark',
    background:{
      //paper:'#090B23',
      //default:'#090B23'
    }
  },
  typography:{
    //fontFamily:'Comic Sans Ms',
    body1:{
      fontSize:'1.1rem',
    
    },
    // h1:{
    //   color:'red'
    // }
  },
  shape:{
    borderRadius:0,
  },
  //spacing:8,
  overrides:{
    MuiButton:{
      root:{
        textTransform:'none',
        
      }
    },

  },
  props:{
    MuiButton:{
      disableRipple:true,
      // variant:'text'
    },
    MuiButtonGroup:{
      disableRipple:true,
      // variant:'outlined'
    },
    MuiTextField:{
      InputLabelProps:{
        shrink:true
      }
    }
    
  }

})   










useEffect(()=>{///<<<<<<---------COMPONENT DID MOUNT-------------------------------------

console.log(`APPLICATION_MOUNTED:..`)



return ()=>{//<<<-------------- COMPONENT WILL UNMOUNT----




}
//eslint-disable-next-line react-hooks/exhaustive-deps
},[])







  
   














var ResponsiveMediaQuery ={
mobile_xs:mobile_xs,
tablet_sm:tablet_sm,
desktop_md:desktop_md,
desktop_lg:desktop_lg,
desktop_xl:desktop_xl
}

var SharedFunction={}


      
return( ///<<<<<<<<<-------------------Returning Main App---------------------
<div className='App' id={mobile_xs===true||tablet_sm===true?'MOBILE_TABLET_VIEW':'DESKTOP_VIEW'}>
<div id={mobile_xs===true?'MOBILE_VIEW_ONLY':tablet_sm===true?'TABLET_VIEW_ONLY':desktop_md===true?'DESKTOP_MEDIUM_VIEW_ONLY':desktop_lg===true || desktop_xl===true?'DESKTOP_LARGE_VIEW':'DESKTOP_LARGER_VIEW'}> 

<Applicationcontext.Provider value={{ApplicationState:Appstate,UserProfile:UserProfile,theme:theme,ResponsiveMediaQuery:ResponsiveMediaQuery}}>
<ThemeProvider theme={theme}>      
<div>
  {/* Topbar Start */}
  <div className="container-fluid bg-dark px-5 d-none d-lg-block">
    <div className="row gx-0">
      <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
        <div className="d-inline-flex align-items-center" style={{height: 45}}>
          <small className="me-3 text-light"><i className="fa fa-map-marker-alt me-2" />{UserProfile.CompanyProfile.office_location.physical_address}</small>
          <small className="me-3 text-light"><i className="fa fa-phone-alt me-2" />{UserProfile.CompanyProfile.phone_number2}</small>
          <small className="text-light"><i className="fa fa-envelope-open me-2" />{UserProfile.CompanyProfile.company_email2}</small>
        </div>
      </div>
      <div className="col-lg-4 text-center text-lg-end">
        <div className="d-inline-flex align-items-center" style={{height: 45}}>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href><i className="fab fa-twitter fw-normal" /></a>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href><i className="fab fa-facebook-f fw-normal" /></a>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href><i className="fab fa-linkedin-in fw-normal" /></a>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href><i className="fab fa-instagram fw-normal" /></a>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href><i className="fab fa-youtube fw-normal" /></a>
        </div>
      </div>
    </div>
  </div>
  {/* Topbar End */}

  {/* Navbar & Carousel Start */}
  <div className="container-fluid position-relative p-0">
    <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
      <a href="/" className="navbar-brand p-0">
        <div className='logo-frame'>
         <img src={UserProfile.CompanyProfile.company_logo} alt='' style={{maxWidth:'100%',maxHeight:'100%'}}/>
        </div>
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span className="fa fa-bars" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto py-0">
           <Link to="/" className={pathname==='/'?'nav-item nav-link active':'nav-item nav-link'}>Home</Link>
           <Link to="services" className={pathname==='/services'?'nav-item nav-link active':'nav-item nav-link'}>Services</Link>
           <Link to="/about" className={pathname==='/about'?'nav-item nav-link active':'nav-item nav-link'}>About</Link>
           <Link to="/contact" className={pathname==='/contact'?'nav-item nav-link active':'nav-item nav-link'}>Contact</Link>
           {/* <div className="nav-item dropdown">
            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">More</a>
            <div className="dropdown-menu m-0">
              <Link to="price.htm" className="dropdown-item">Pricing Plan</Link>
              <a href="feature.html" className="dropdown-item">Our features</a>
              <a href="team.html" className="dropdown-item">Team Members</a>
              <a href="testimonial.html" className="dropdown-item">Testimonial</a>
              <a href="quote.html" className="dropdown-item">Free Quote</a>
            </div>
           </div> */}
        </div>
        <a href="/" className="btn btn-primary py-2 px-4 ms-3">Company Profile</a>
      </div>
    </nav>

    {pathname!=='/'?   
     <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: 90}}>
      <div className="row py-5">
       <div className="col-12 pt-lg-5 mt-lg-5 text-center">
         <h1 className="display-4 text-white animated zoomIn">About Us</h1>
         <a href='' className="h5 text-white">Home</a>
         <i className="far fa-circle text-white px-2" />
         <a href className="h5 text-white">About</a>
    </div>
  </div>
</div>
:null}

 
   
    {pathname==='/'?
     <Fragment>
      <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
          
          
          <div className="carousel-item active">
            <img className="w-100" src="images/slider-1.jpg" alt="Image" />
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{maxWidth: 900}}>
                <h5 className="text-white text-uppercase mb-3 animated slideInDown">Court Brokers</h5>
                <h1 className="display-1 text-white mb-md-4 animated zoomIn">Innovative Court Broker Service You Can Rely</h1>
                <a href="quote.html" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
                <a href className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</a>
              </div>
            </div>
          </div>
          
          <div className="carousel-item">
            <img className="w-100" src="images/slider-1.jpg" alt="Image" />
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{maxWidth: 900}}>
                <h5 className="text-white text-uppercase mb-3 animated slideInDown">Public Auctioneer</h5>
                <h1 className="display-1 text-white mb-md-4 animated zoomIn">We Oversee your public Auctions &amp; ensure a high bid</h1>
                <a href="quote.html" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
                <a href className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</a>
              </div>
            </div>
          </div>



        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>

    </Fragment>:null}
  </div>
  {/* Navbar & Carousel End */}






  {pathname==='/'?
  <div className="container-fluid facts py-5 pt-lg-0">
    <div className="container py-5 pt-lg-0">
      <div className="row gx-0">
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
          <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{height: 150}}>
            <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{width: 60, height: 60}}>
              <i className="fa fa-users text-primary" />
            </div>
            <div className="ps-4">
              <h5 className="text-white mb-0">Happy Clients</h5>
              <h1 className="text-white mb-0" data-toggle="counter-up">12k+</h1>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
          <div className="bg-light shadow d-flex align-items-center justify-content-center p-4" style={{height: 150}}>
            <div className="bg-primary d-flex align-items-center justify-content-center rounded mb-2" style={{width: 60, height: 60}}>
              <i className="fa fa-check text-white" />
            </div>
            <div className="ps-4">
              <h5 className="text-primary mb-0">Projects Done</h5>
              <h1 className="mb-0" data-toggle="counter-up">10k+</h1>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
          <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{height: 150}}>
            <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{width: 60, height: 60}}>
              <i className="fa fa-award text-primary" />
            </div>
            <div className="ps-4">
              <h5 className="text-white mb-0">Years Experience</h5>
              <h1 className="text-white mb-0" data-toggle="counter-up">5</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  :null}






   <RouteNavigation />


  {/* Vendor End */}
  {/* Footer Start */}
  <div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
      <div className="row gx-5">
        <div className="col-lg-4 col-md-6 footer-about">
          <div id='footer-sub-about' className="d-flex flex-column align-items-center justify-content-center text-center h-100  p-4">
            <a href="/" className="navbar-brand">
              <h1 className="m-0 text-white">
                <img src={UserProfile.CompanyProfile.company_logo} alt='' style={{maxWidth:'80%'}}/>
                </h1>
            </a>
            <p className="mt-3 mb-4">
            Rock City Takers limited is an experienced incorporated Company, where expertise meets
             integrity in the sector of debt recovery, public auctioneering, and court blocking services.
              Established with a commitment to navigating the intricate landscapes of financial recovery
               and legal proceedings, we stand as a beacon of reliability and professionalism.......

             
             </p>
            <form action>
              <div className="input-group">
                <input type="text" className="form-control border-white p-3" placeholder="Your Email" />
                <button className="btn btn-dark">Sign Up</button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-lg-8 col-md-6">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-12 pt-5 mb-5">
              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                <h3 className="text-light mb-0">Get In Touch</h3>
              </div>
              <div className="d-flex mb-2">
                <i className="bi bi-geo-alt text-primary me-2" />
                <p className="mb-0">
                  {UserProfile.CompanyProfile.office_location.physical_address},
                  {UserProfile.CompanyProfile.office_location.region}
                  </p>
              </div>
              <div className="d-flex mb-2">
                <i className="bi bi-envelope-open text-primary me-2" />
                <p className="mb-0">
                  {UserProfile.CompanyProfile.company_email1}
                </p>
              </div>
              <div className="d-flex mb-2">
                <i className="bi bi-telephone text-primary me-2" />
                <p className="mb-0">
                  {UserProfile.CompanyProfile.phone_number1}
                </p>
              </div>
              <div className="d-flex mt-4">
                <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-twitter fw-normal" /></a>
                <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-facebook-f fw-normal" /></a>
                <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-linkedin-in fw-normal" /></a>
                <a className="btn btn-primary btn-square" href="#"><i className="fab fa-instagram fw-normal" /></a>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                <h3 className="text-light mb-0">Quick Links</h3>
              </div>
              <div className="link-animated d-flex flex-column justify-content-start">
                <a className="text-light mb-2" href="/"><i className="bi bi-arrow-right text-primary me-2" />Home</a>
                <a className="text-light mb-2" href="/about"><i className="bi bi-arrow-right text-primary me-2" />About Us</a>
                <a className="text-light mb-2" href="/services"><i className="bi bi-arrow-right text-primary me-2" />Our Services</a>
                <a className="text-light mb-2" href="/"><i className="bi bi-arrow-right text-primary me-2" />Meet The Team</a>
                <a className="text-light mb-2" href="/"><i className="bi bi-arrow-right text-primary me-2" />Latest Blog</a>
                <a className="text-light" href="/contact"><i className="bi bi-arrow-right text-primary me-2" />Contact Us</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                <h3 className="text-light mb-0">Popular Services Tags</h3>
              </div>
              <div className="link-animated d-flex flex-column justify-content-start">
                <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Buying and selling of estates</a>
                <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Private Investigation</a>
                <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Court Broker</a>
                <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Debt Collection</a>
                <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Leasing and renting of houses</a>
                <a className="text-light" href="#"><i className="bi bi-arrow-right text-primary me-2" />Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid text-white" style={{background: '#061429'}}>
    <div className="container text-center">
      <div className="row justify-content-end">
        <div className="col-lg-8 col-md-6">
          <div className="d-flex align-items-center" style={{height: 75}}>
           
           
            <p className="mb-0">©
             <a className="text-white border-bottom" href="#">RockcityTakers LTD</a>
             . All Rights Reserved ,  <a className="text-white border-bottom" href="/"> </a>
            </p>

             &nbsp;  &nbsp;

            <a className="text-white border-bottom" href="https://wiflexi.com">  powered by idigito</a>


          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Footer End */}
</div>


<a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i class="bi bi-arrow-up"></i></a>




</ThemeProvider>
</Applicationcontext.Provider> 
</div>
</div> 
)}
export default App