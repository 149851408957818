import React ,{Fragment,useEffect,useContext}from 'react';
import './Portifoliopage.scss';
import {Applicationcontext} from "../../Applicationcontext";
import {Grid} from '@mui/material';
import {Web} from '@mui/icons-material';
import { PortifolioSection } from "../PortifolioSection/PortifolioSection";



  
const  Portifoliopage=({})=> {


const Applicationstate = useContext(Applicationcontext)

const {ResponsiveMediaQuery,ServerProcessLoading,UserProfile,Functions,Appstate} = Applicationstate

const {CompanyProfile} = UserProfile


const {company_logo,company_motto,company_name,theme_maincolor,theme_subcolor,theme_decoration_color}=CompanyProfile 



const {mobile_xs,tablet_sm} = ResponsiveMediaQuery


useEffect(()=>{//----------COMPONENT DID MOUNT
//$(window).scrollTop(25)//scroll to top of the component
   



return()=>{//<<<<<<<<<<<----------------Component Will Unmount

}
//eslint-disable-next-line react-hooks/exhaustive-deps 
},[])

//clientprojects/digitoflex.png


const [ProjectSlideList,setProjectSlideList]=React.useState([
{company_name:'priminainvestment',company_logo:'clientprojects/priminainvestment+logo.png',company_motto:'fishfeeding',website_screenshot:'clientprojects/priminainvestment.png',website_url:'http://priminainvestment.com'},
{company_name:'godblesstar',company_logo:'clientprojects/godblesstar+logo.png',company_motto:'WE ARE PURSUING PURPOSE AND KEEPING VISION',website_screenshot:'clientprojects/godblesstar.png',website_url:'http://priminainvestment.com'},
{company_name:'digitoflex',company_logo:'clientprojects/digitoflex+logo.png',company_motto:'flexible digital soltion',website_screenshot:'/clientprojects/digitoflex.png',website_url:'http://priminainvestment.com'},
{company_name:'organic',company_logo:'clientprojects/organic+logo.png',company_motto:'pure & fresh vegitables',website_screenshot:'/clientprojects/organic.png',website_url:'http://priminainvestment.com'},
{company_name:'homemarket',company_logo:'clientprojects/homemarket+logo.png',company_motto:'THE BEST E-COMMERCE',website_screenshot:'/clientprojects/homemarket.png',website_url:'http://priminainvestment.com'},

])






const PROJECT_LIST_MAP = ProjectSlideList.map(function(object,index){
   return(
   <Grid item xs={12} md={6} key={`project-card${index}`}>  
      <div className='project-card' style={{backgroundImage:`url(${object.website_screenshot})`}}> 
      <Grid container spacing={0}> 

      <Grid item xs={12} md={8}>  
        <div className='project-detail' style={{backgroundColor:theme_subcolor}}>
            
          <div className='logo-circle' style={{backgroundImage:`url(${object.company_logo})`}}></div>

           <div className='brandname-section'>
             <h3 className='company-name'>{object.company_name}</h3>
             <a className='visitsite-span' href={object.website_url}>VISIT SITE <Web /></a>
           </div>


        </div>
        </Grid>
   
    
   </Grid>
     </div>
  </Grid>
     )
   }
 ) 
   



return ( //------------------- RETURN HTML
<Fragment>
<div id='Portifoliopage'>
  
<div className={mobile_xs===true || tablet_sm===true?'container-fluid':'container'}>
<Grid container spacing={2}> 



 
 <Grid item xs={12}>  <PortifolioSection   /> </Grid>





</Grid>
</div>
</div>
</Fragment>
);
}
export {Portifoliopage};
    
    