import React ,{Fragment,useEffect,useContext}from 'react';
import './Digitalventure.scss';
import {Applicationcontext} from "../../Applicationcontext";
import {Grid, Typography} from '@mui/material';



  
const  Digitalventure=({})=> {


const Applicationstate = useContext(Applicationcontext)

const {ResponsiveMediaQuery,ServerProcessLoading,UserProfile,Functions,Appstate} = Applicationstate

const {CompanyProfile} = UserProfile


const {company_logo,company_motto,company_name,theme_maincolor,theme_subcolor,theme_decoration_color}=CompanyProfile 



useEffect(()=>{//----------COMPONENT DID MOUNT
//$(window).scrollTop(25)//scroll to top of the component
   

    

    
    


return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
   // eslint-disable-next-line react-hooks/exhaustive-deps 
},[])




const {mobile_xs,tablet_sm} = ResponsiveMediaQuery


 
    
   
return ( //------------------- RETURN HTML
<Fragment>
<div id='Digitalventure' className={mobile_xs===true || tablet_sm ===true?'Servicepage_MOBILE':'Servicepage_DESKTOP'}>
<div className="container py-5">  
<Grid container spacing={1}> 

<Grid item xs={12}> 
<h6 className="text-secondary font-weight-semi-bold text-uppercase mb-3">Digital Venture Program.</h6>
 <h1 className="mb-4 section-title">We Venture up with Digital Startups</h1>
 <br />
 
 </Grid>

 <Grid item xs={12}>  
 <div  className='heading_paper_wrapper'>

 <div className='service-circle' style={{border:`4px solid ${theme_subcolor}`,backgroundImage:`url(svg/webdesign&webdevelopment.svg)`}}></div>
     
     <div className='service-title-wrapper' style={{backgroundColor:theme_subcolor}}>
      <span>DIGITAL VENTURE PROGRAM.</span>
     </div>

     <div className='service-content-wrapper'>
      <div className='content-margin'></div>
      <div className='content'>
      <Grid container spacing={0}> 
  
         <Grid item xs={12} md={8}>  
         <div className='text-wrapper'> 
          <p>
            
            Digital venture program is a program launched for the purpose of investing in new upcoming 
            digital startups, and businesses whose business running relies heavily on digital infrastructure, 
            our aim is to provide full digital support on the entire IT Infrastructure required to launch a 
            business ideal.

            <br /><br />
            This is done by us becoming part of the project /business startups and we take responsibility 
            for creating the entire digital infrastructure needed to run the business which includes
             developing and designing an Information Management system, an official website for the
              business/project running necessary digital marketing campaign for the project as well 
              renovating the existing ideal to better fit the current marketing demand.

              <br /> <br />
              Our investment plans cover various percentage schemes depending on the size of the project 
              ranging from 10% up to 50% of the project's digital infrastructure budget. 
              </p>

              <h1 className="mb-4 section-title">We partnering with your project/startups from  0 up to lauching</h1>
          </div>
         </Grid>  

          <Grid item xs={12} md={4}>

            <div className='service-image' style={{backgroundImage:`url(images/webdesign.jpg)`}}> </div>

          </Grid>    

        </Grid>
      </div>
     </div>

     <div className='support-phase-wrapper'>
    

     <Grid container spacing={2}> 
          
          <Grid item xs={6} md={3}> 
            <div className='mission-circle-wrapper' style={{backgroundColor:theme_subcolor}}>
            
              <div className='mission-circle' style={{backgroundImage:'url(svg/idea-renovation.svg)'}}></div>
              <Typography variant='h6' color='#fff'>Ideal Renovation</Typography>

             </div> 
          </Grid>
          
          <Grid item xs={6} md={3}>
            <div className='mission-circle-wrapper' style={{backgroundColor:theme_subcolor}}>
                 
            
               <div className='mission-circle' style={{backgroundImage:'url(svg/building-infrastructure.svg)'}}></div>
               <Typography variant='h6' color='#fff'>Building Infrastructure</Typography>

            </div>    
          </Grid>
          
          <Grid item xs={6} md={3}> 
           <div className='mission-circle-wrapper' style={{backgroundColor:theme_subcolor}}>
              <div className='mission-circle' style={{backgroundImage:'url(svg/lauching.svg)'}}></div>
              <Typography variant='h6' color='#fff'>Lauching</Typography>
           </div>   
          </Grid>

          <Grid item xs={6} md={3}> 
           <div className='mission-circle-wrapper' style={{backgroundColor:theme_subcolor}}>
              <div className='mission-circle' style={{backgroundImage:'url(svg/marketing.svg)'}}></div>
              <Typography variant='h6' color='#fff'>Marketing</Typography>
           </div>   
          </Grid>


          
      </Grid>

        
     
     </div> 

 </div>
 </Grid>









</Grid>
</div>
</div>
</Fragment>
  );
}
export {Digitalventure};
    
    