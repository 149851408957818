import React ,{Fragment,useEffect,useContext}from 'react';
import './PortifolioSection.scss';
import {Applicationcontext} from "../../Applicationcontext";
import {Grid, Typography} from '@mui/material';
import {Web} from '@mui/icons-material';



  
const  PortifolioSection=({})=> {


const Applicationstate = useContext(Applicationcontext)

const {ResponsiveMediaQuery,ServerProcessLoading,UserProfile,Functions,Appstate} = Applicationstate

const {CompanyProfile} = UserProfile


const {company_logo,company_motto,company_name,theme_maincolor,theme_subcolor,theme_decoration_color}=CompanyProfile 



const {mobile_xs,tablet_sm} = ResponsiveMediaQuery


useEffect(()=>{//----------COMPONENT DID MOUNT
//$(window).scrollTop(25)//scroll to top of the component
   



return()=>{//<<<<<<<<<<<----------------Component Will Unmount

}
//eslint-disable-next-line react-hooks/exhaustive-deps 
},[])

//portifolio/digitoflex.png


const [ProjectSlideList,setProjectSlideList]=React.useState([
{company_name:'priminainvestment',company_logo:'portifolio/priminainvestment+logo.png',company_motto:'fishfeeding',website_screenshot:'portifolio/priminainvestment.png',website_url:'http://priminainvestment.com'},
{company_name:'godblesstar',company_logo:'portifolio/godblesstar+logo.png',company_motto:'WE ARE PURSUING PURPOSE AND KEEPING VISION',website_screenshot:'portifolio/godblesstar.png',website_url:'http://godblesstar.com'},
{company_name:'amilinvestment',company_logo:'portifolio/amilinvestment.co.tz+logo.png',company_motto:'flexible digital soltion',website_screenshot:'/portifolio/amilinvestment.co.tz.png',website_url:'http://amilinvestment.co.tz'},
])






const PROJECT_LIST_MAP = ProjectSlideList.map(function(object,index){
   return(
   <Grid item xs={12} md={6} key={`project-card${index}`}>  
      <div className='project-card' style={{backgroundImage:`url(${object.website_screenshot})`}}> 
      <Grid container spacing={0}> 

      <Grid item xs={12} md={8}>  
        <div className='project-detail' style={{backgroundColor:theme_maincolor}}>
            
          <div className='logo-circle' style={{backgroundImage:`url(${object.company_logo})`}}></div>

           <div className='brandname-section'>
             <h3 className='company-name'>{object.company_name}</h3>
             <a className='visitsite-span' href={object.website_url} target='_blank'>VISIT SITE <Web /></a>
           </div>


        </div>
        </Grid>
   
    
   </Grid>
     </div>
  </Grid>
     )
   }
 ) 
   



return ( //------------------- RETURN HTML
<Fragment>
<div id='PortifolioSection'>
<Grid container spacing={2}> 


<Grid item xs={12}>
<div className='portifolio-header-section'>
  
   <Typography variant='h2'>CLIENT PROJECTS</Typography>
  
  </div>  
</Grid>


 {PROJECT_LIST_MAP}



</Grid>
</div> 
</Fragment>
);
}
export {PortifolioSection};
    
    