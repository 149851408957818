import React ,{Fragment,useEffect,useContext}from 'react';
import {Link,useLocation} from 'react-router-dom';
import './Aboutpage.scss';
import {Applicationcontext} from "../../Applicationcontext";
import {Grid} from "@mui/material";
import {CorevalueView} from "../CorevalueView/CorevalueView";
import {ObjectiveView} from "../ObjectiveView/ObjectiveView";


  
const  Aboutpage=({})=> {

const Applicationstate = useContext(Applicationcontext)

const {ResponsiveMediaQuery,ServerProcessLoading,UserProfile,Functions,Appstate} = Applicationstate

const {CompanyProfile} = UserProfile


const {theme_maincolor,theme_subcolor,phone_number1}=CompanyProfile 


const Location = useLocation()

const {pathname} = Location

const DisplayFull = pathname==='/about'?true:false;


useEffect(()=>{//----------COMPONENT DID MOUNT




return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
   // eslint-disable-next-line react-hooks/exhaustive-deps 
},[])






 
    
   
return ( //------------------- RETURN HTML
<Fragment>
<div id='Aboutpage'>
<div className="content-padding py-0">

  <div className="row g-3">
    <div className="col-lg-7">
       <div className="section-title position-relative pb-3 mb-5">
        <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
        <h1 className="mb-0">Get To Know About Rock City Takers limited</h1>
       </div>
        <p className="mb-4">
         Rock City Takers limited is an experienced incorporated Company, where expertise meets
         integrity in the sector of debt recovery, public auctioneering, and court blocking services. 
         Established with a commitment to navigating the intricate landscapes of financial recovery
         and legal proceedings, we stand as a beacon of reliability and professionalism.
         <br /><br /> 
         In the scope of public auctioneering, Rock City Takers Limited ,  shines as a leading force.
         With a meticulous approach, we orchestrate auctions that maximize value for our clients,
         whether liquidating assets or managing estate sales. Our team's expertise ensures a seamless
         process, from appraisal to auctioneer services, providing a comprehensive solution for those
         seeking to optimize returns on their assets.     
       </p>
      <div className="row g-0 mb-3">
        <div className='aboupage-popular-tags'>
          <Grid container spacing={2}> 

          <Grid item xs={12} sm={6} md={4} lg={3}> 
           <div className='popular-tag-card'>
               <div className='popular-tag-icon'> <img src='svg/debt-collection.svg' alt='' style={{maxWidth:'90%'}}/></div>
                <b>Debt Collection</b>
           </div>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}> 
           <div className='popular-tag-card'>
               <div className='popular-tag-icon'><img src='svg/public-auctioneer.svg' alt='' style={{maxWidth:'90%'}} /></div>
                <b>Public Auctioneer</b>
           </div>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}> 
           <div className='popular-tag-card'>
               <div className='popular-tag-icon'><img src='svg/court-brocker.svg' alt='' style={{maxWidth:'90%'}} /></div>
                <b>Court Brocker</b>
           </div>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}> 
           <div className='popular-tag-card'>
               <div className='popular-tag-icon'><img src='svg/court-brocker.svg' alt='' style={{maxWidth:'90%'}} /></div>
                <b>Private Investigation</b>
           </div>
          </Grid>

 
           </Grid>
        </div>
      </div>
      
      <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s" style={{visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeIn'}}>
        <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: 60, height: 60}}>
          <i className="fa fa-phone-alt text-white" />
        </div>
        <div className="ps-4">
          <h5 className="mb-2"><a href={`tel:${phone_number1}`}>Call For Any Enquiries</a></h5>
          <h4 className="text-primary mb-0"><a href={`tel:${phone_number1}`}>{phone_number1}</a></h4>
        </div>
      </div>
       {DisplayFull===false?
        <Link to="/about" className="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s" style={{visibility: 'visible', animationDelay: '0.9s', animationName: 'zoomIn'}}>ReadMore</Link>
       :null}
        </div>
    <div className="col-lg-5" style={{minHeight: 500}}>
      <div className="position-relative h-100">
        <img  className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="images/about-photo-1.jpg" style={{objectFit: 'cover', visibility: 'visible', animationDelay: '0.9s', animationName: 'zoomIn'}} />
      </div>
    </div>
  </div>

  <br /><br />
  {DisplayFull===true?
  <div className="row g-3">
  <div className="col-lg-5" style={{minHeight: 220}}>
      <div className="position-relative h-100">
        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="images/vision-photo.jpg" style={{objectFit: 'cover', visibility: 'visible', animationDelay: '0.9s', animationName: 'zoomIn'}} />
      </div>
    </div>

    <div className="col-lg-7">
       <div className="section-title position-relative pb-3 mb-5">
        <h5 className="fw-bold text-primary text-uppercase">Vision</h5>
        <h1 className="mb-0">Our Vision</h1>
       </div>
        <p className="mb-4">
        To establish and emerge as one of the most preferred Court broker firms, providing 
        Innovative solutions to ensure total customer satisfaction. In the process, 
        we aspire to combine excellence, quality and timely service delivery. Sustainability
         will be the key factor whilst demonstrating and earning a reputation for good ethics
          and trusted services to all our customers.
         <br /><br />   
       </p>      
     </div>
  </div>
  :null}

  <br /><br />
  {DisplayFull===true?
  <div className="row g-3">
    <div className="col-lg-7">
       <div className="section-title position-relative pb-3 mb-5">
        <h5 className="fw-bold text-primary text-uppercase">Mission</h5>
        <h1 className="mb-0">Our Mission</h1>
       </div>
        <p className="mb-4">
         Our Mission is to provide service that is transparent and sound, persistent in its ambition to improve
          and provide quality auctioneering and real estate services. We work to establish solid relationships
           with our policy holders, our employees and the community we live and conduct our business in.
            Our work ethic is based on a commitment to meet the principles outlined above and continue to serve 
            the best interests of all our clients. We desire to be an ethical, 
            principled and value-based organization setting new benchmarks in the industry.
         <br /><br />   
       </p>      
     </div>
     <div className="col-lg-5" style={{minHeight: 220}}>
      <div className="position-relative h-100">
        <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="images/mission-photo.jpg" style={{objectFit: 'cover', visibility: 'visible', animationDelay: '0.9s', animationName: 'zoomIn'}} />
      </div>
    </div>

    <div className="col-12">
      <div className="position-relative h-100">
        <div className='corevalue-frame'>
         <h1 className="mb-0">Our Core Value</h1>
         <Grid container spacing={2}> 
          
           <Grid item xs={12} sm={4}>
            <span>
               Our company values strictly aimed at providing stringent quality control and a standard
               of services and products in its business arena to enhance accountability and strict 
               control of operating aspects.
               <br /><br />
               <b>Environmental <br /></b> 
               We will always work according to the court broker and process server code as per the court
               regulations to ensure the above, all employees will receive a specific number of hours, 
               technical and practical training.

            </span>
           </Grid>
          <Grid item xs={12} sm={4}>
               <div className='corevalue-middle-image' style={{backgroundImage:`url(images/core-value-image.jpg)`}}></div>
            </Grid>
          <Grid item xs={12} sm={4}>
               <span>
                We will strive to meet the needs of our customers with the quality service and workmanship.
                We will deliver high standards of services based on proper selection, training and
                supervision of our staff and personnel.
              </span>
            </Grid>
          </Grid>
        </div>
        </div>
    </div>


    
    <div className="col-12">
      <div className="position-relative h-100">
        <div className='standard-frame'>
         <h1 className="mb-0">These are some of the standards we follows  to ensure we delivery a firm court broker service towards customer/clients satisfaction and judiciary standard.</h1>
         <Grid container spacing={2}> 
          
           <Grid item xs={12} sm={7}>
               <ul>
                <li>We will provide a free, written estimate within 72 hours.</li>
                <li>We will begin work within 7 days of receiving a signed agreement.</li>
                <li>We will not interrupt work for any reason until we finish a job.</li>
                <li>We will use the highest quality materials available.</li>
                <li>We will follow up after every job to be sure the customer is satisfied.</li>
                <li>We will guarantee satisfaction for all of our work.</li>
               </ul>
           </Grid>

           <Grid item xs={12} sm={5}><img src="images/about-last.jpg" alt='' style={{width:'100%',maxHeight:300}} /></Grid>
          
          </Grid>
        </div>
        </div>
    </div>



  </div>
  :null}




</div>




</div>
</Fragment>
);
}
export {Aboutpage};
    
    